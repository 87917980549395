<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan" class="range-date">
            <a-form-item label="录单时间">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户业务编号">
              <a-input v-model.trim="queryParam['customer_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model.trim="queryParam['serial_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报价编号">
              <a-input v-model.trim="queryParam['quota_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报关单号">
              <a-input v-model.trim="queryParam['declare_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合约号">
              <a-input v-model.trim="queryParam['deal_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="主单号">
              <a-input v-model.trim="queryParam['order_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="箱号">
              <a-input v-model.trim="queryParam['box_info']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用">
              <a-select
                v-model="queryParam['fee_id']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in feeOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam['pay_target']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in payTargetOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                showSearch
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam['fee_status']"
              >
                <a-select-option v-for="item in GLOBAL.feeStatusMaps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="订单类型">
              <a-select
                allowClear
                placeholder="请选择订单类型"
                style="width: 100%"
                v-model="queryParam['order_kind']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in orderKindOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="业务类型">
              <a-select
                allowClear
                placeholder="请选择订单类型"
                style="width: 100%"
                v-model="queryParam['business_type']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option :key="0">保税仓储</a-select-option>
                <a-select-option :key="1">海运进口</a-select-option>
                <a-select-option :key="2">海运出口</a-select-option>
                <a-select-option :key="3">空运进口</a-select-option>
                <a-select-option :key="4">空运出口</a-select-option>
                <a-select-option :key="11">进口内装</a-select-option>
                <a-select-option :key="12">出口内装</a-select-option>
                <a-select-option :key="7">公铁运输</a-select-option>
                <a-select-option :key="13">其他</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam['order_kind'] === 5">
            <a-form-model-item label="EP号">
              <a-input v-model.trim="queryParam.ep_no" placeholder="根据拖运EP号码搜索" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam['order_kind'] === 5">
            <a-form-model-item label="拖运编号">
              <a-input v-model.trim="queryParam.business_no" placeholder="根据拖运编号搜索" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam['order_kind'] === 5">
            <a-form-model-item label="拖运业务日期">
              <a-range-picker @change="conShipmentDateChange" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-affix :offsetTop="50">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="auditOpen('basic_audit')">审核</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="auditOpen('undo_audit')">反审核</a-button>
        <a-button type="primary" @click="createBalance" :disabled="!selectedRowKeys.length">创建结算单</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="createPayment('undo_audit')">创建付款单</a-button>
        <a-button type="primary" @click="handleExport('export_charge_data')">导出</a-button>
      </a-affix>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="order_id"
      dynamicColumns
      :columns="defaultColumns"
      :data="loadData"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.selectItem,
        onSelectAll: this.selectAllItem
      }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="chargeFeeItem" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          <a-tag :color="chargeFeeStatusColor(item)" style="margin-bottom:2px;">
            {{ feeChargeStatusName(item) }}
          </a-tag>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <div slot="payFeeItem" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          <a-tag :color="payFeeStatusColor(item)" style="margin-bottom:2px;">
            {{ feePayStatusName(item) }}
          </a-tag>
          <span>{{ item.pay_target_name }} </span>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <template slot="action" slot-scope="text, record">
        <a @click="balanceDetail(record)" v-if="record.order_kind !== 4">查看结算单</a>
        <a-divider type="vertical" v-if="record.order_kind !== 4"/>
        <a @click="paymentDetail(record)">查看付款单</a>
      </template>
    </s-table>

    <select-drawer title="选择的订单" :visible="selectedVisible" :container="getContainer">
      <template v-slot:summary>
        <div class="select-summary">
          <span>{{ selectedSummary }}</span>
          <a @click="clearSelectItem">清除</a>
        </div>
      </template>
      <template v-slot:list>
        <div class="select-list-item" v-for="item in selectedRows" :key="item.order_id">
          <p>报价编号：{{ item.quota_num }}</p>
          <p>委托客户：{{ item.agency_name }}</p>
          <div style="margin: 5px 0; text-align: center">
            <a @click="removeSelectItem(item.order_id)">删除</a>
          </div>
        </div>
      </template>
    </select-drawer>

    <a-modal
      :visible="balanceVisible"
      :confirmLoading="balanceConfirmLoading"
      title="创建结算单"
      :width="720"
      @cancel="balanceVisible = false"
      @ok="balanceSubmit"
    >
      <a-checkbox
        :indeterminate="indeterminate"
        :checked="checkAll"
        @change="onCheckAllChangeBalance"
        style="padding-left:10px;padding-bottom:10px;"
      >
        全选
      </a-checkbox>
      <a-tag color="#f50" style="margin-right:10px;">应收合计:{{ chargeMoney }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;">应付合计:{{ payMoney }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;">利润合计:{{ profit }}</a-tag>
      <a-checkbox-group v-model="checkedBalanceList" @change="onChangeBalance" style="width:100%;">
        <div style="margin-bottom: 20px;" v-for="item in balanceData" :key="item.id">
          <p style="margin: 10px 0;font-size: 16px;font-weight:bold">报价编号：{{ item.quota_num }}</p>
          <div class="fee-area">
            <div class="fee-list">
              <p style="margin: 5px auto;">应收费用</p>
              <div v-for="c in item.charge" :key="c.id">
                <a-checkbox :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
            <div class="fee-list">
              <p style="margin: 5px auto;">应付费用</p>
              <div v-for="p in item.pay" :key="p.id">
                <a-checkbox :value="p.id"> {{ p.fee_name }}:{{ p.money }}{{ p.currency_name }}-{{ p.pay_target_name }} </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>

    <a-modal
      :visible="auditVisible"
      :confirmLoading="auditConfirmLoading"
      :title="auditType === 1 ? '审核' : '反审核'"
      :width="720"
      @cancel="auditVisible = false"
      @ok="submitAudit"
    >
      <a-checkbox
        :indeterminate="auditIndeterminate"
        :checked="auditCheckAll"
        @change="onCheckAllChangeAudit"
        style="padding-left:10px;padding-bottom:10px;"
      >
        全选
      </a-checkbox>
      <a-tag color="#f50" style="margin-right:10px;">应收总和:{{ auditChargeTotal.toFixed(2) }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;" v-if="queryParam['order_kind'] === 5">应付恒发总和:{{ auditHFPayTotal.toFixed(2) }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;" v-if="queryParam['order_kind'] === 5">应付鹏路总和:{{ auditPLPayTotal.toFixed(2) }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;" v-if="queryParam['order_kind'] === 5">应付三利总和:{{ auditSLPayTotal.toFixed(2) }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;" v-if="queryParam['order_kind'] === 5">应付召集总和:{{ auditZJPayTotal.toFixed(2) }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;" v-else>应付总和:{{ auditPayTotal.toFixed(2) }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;">利润:{{ auditProfitTotal.toFixed(2) }}</a-tag>
      <a-checkbox-group v-model="checkedAuditList" @change="onChangeAudit" style="width:100%;">
        <div style="margin-bottom: 20px;" v-for="item in auditData" :key="item.id">
          <p style="margin: 10px 0;font-size: 16px;font-weight:bold">报价编号：{{ item.quota_num }}</p>
          <div class="fee-area">
            <div class="fee-list">
              <p style="margin: 5px auto;">应收费用</p>
              <div v-for="c in item.charge" :key="c.id">
                <a-checkbox :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
            <div class="fee-list">
              <p style="margin: 5px auto;">应付费用</p>
              <div v-for="c in item.pay" :key="c.id">
                <a-checkbox :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>

    <a-modal
      :visible="balanceDetailVisible"
      rowKey="balanceId"
      title="结算单详情"
      :width="1200"
      @cancel="balanceDetailVisible = false"
    >
      <a-table :columns="balanceColumns" :dataSource="balanceDetailData" :pagination="false">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="action" slot-scope="text, record">
          <a @click="previewChargeDetail(record)">结算单详情</a>
        </template>
      </a-table>
    </a-modal>

    <a-modal
      :visible="printItmsBillModel"
      :confirmLoading="printItmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印国贸结算单"
      @cancel="printItmsBillModel = false"
    >
      <div id="printItmsBill">
        <itms-bill :currentData="currentData" :balanceData="balanceBillData"></itms-bill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printItmsBillBtn" v-print="printItmsBill">确认打印</a-button>
        <a-button type="primary" @click="printItmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="paymentDetailVisible"
      rowKey="paymentId"
      title="付款单详情"
      :width="1200"
      @cancel="paymentDetailVisible = false"
      @ok="paymentDetailVisible = false"
    >
      <a-table :columns="paymentColumns" :dataSource="paymentDetailData" :pagination="false">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="quotaNumList" slot-scope="text">
          <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
        </div>
        <div slot="receivingDateList" slot-scope="text">
          <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
        </div>
        <template slot="action" slot-scope="text, record">
          <a @click="previewPayDetail(record)">付款单详情</a>
        </template>
      </a-table>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <pay-statement-bill :orderData="printData" :type="orderType"> </pay-statement-bill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printStatementsPayBtn" v-print="printStatementsPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <!--    创建付款单-->
    <a-modal
      :visible="paymentVisible"
      :confirmLoading="paymentConfirmLoading"
      title="创建付款单"
      :width="1020"
      @cancel="paymentVisible = false"
      @ok="paymentSubmit"
    >
      <a-radio-group v-model="checkedPayTarget" @change="changePayTarget" button-style="solid" style="margin-left: 20px">
        <a-radio-button v-for="item in paymentPayTarget" :value="item.id" :key="item.id">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
      <div style="padding: 20px">
        <a-tag color="#f50" style="margin-bottom: 20px">应付合计:{{ payMoney }}</a-tag>
        <edit-table
          :columns="paymentFeeColumns"
          :sourceData="paymentData"
          @getTableDate="getPaymentFeeInfoTableDate"
          :disabled="true">
        </edit-table>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, SelectDrawer, EditTable } from '@/components'
import { getCustomer, getSupplier, getCommonOptions, downloadExcel } from '@/api/common'
import { filterOption } from '@/utils/util'
import {
  getItmsChargeFinancePage,
  ItmsFinanceFunc,
  exportItmsFinance,
  createItmsBalance,
  getBalanceDetailByOrderId,
  getItmsBalanceFunc,
  getPaymentDetailByOrderId,
  getItmsPayBillFunc,
  createItmsPayment
} from '@/api/itms'
import debounce from 'lodash/debounce'
import itmsBill from '@/views/fms/bill/FmsItmsBill'
import payStatementBill from '@/views/components/PayStatementBill'
import moment from 'moment'
export default {
  components: {
    STable,
    itmsBill,
    EditTable,
    payStatementBill,
    SelectDrawer
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      queryParam: {},
      showTotal: '',
      customerInfo: undefined,
      fetching: false,
      customerOps: [],
      chargeMoney: 0,
      payMoney: 0,
      profit: 0,
      paymentFeeColumns: [
        {
          title: '报价编号',
          dataIndex: 'quota_num',
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name',
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency_name',
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '提交金额',
          dataIndex: 'submit_amount',
          extra: {
            extraFunc: this.rowAmountFunc,
            type: 'number'
          }
        }
      ],
      defaultColumns: [],
      // 其他国贸业务类型展示列
      otherColumns: [
        {
          title: '订单编号',
          dataIndex: 'serial_num',
          sorter: false
        },
        {
          title: '订单类型',
          dataIndex: 'order_kind',
          customRender: (text) => {
            for (let i = 0; i < this.orderKindOps.length; i++) {
              if (text === this.orderKindOps[i].value) {
                return this.orderKindOps[i].name
              }
            }
            return '无'
          }
        },
        {
          title: '业务类型',
          dataIndex: 'business_type_name',
          sorter: false
        },
        
        {
          title: '客户业务编号',
          dataIndex: 'customer_num',
          sorter: false
        },
        {
          title: '报价编号',
          dataIndex: 'quota_num'
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 120
        },
        {
          title: '应收费用',
          dataIndex: 'charge_fees',
          width: 320,
          scopedSlots: { customRender: 'chargeFeeItem' }
        },
        {
          title: '应收合计',
          dataIndex: 'charge_total'
        },
        {
          title: '应付费用',
          dataIndex: 'pay_fees',
          width: 320,
          scopedSlots: { customRender: 'payFeeItem' }
        },
        {
          title: '应付合计',
          dataIndex: 'pay_total'
        },
        {
          title: '品名',
          dataIndex: 'name'
        },
        {
          title: '清关类型',
          dataIndex: 'clear_type',
          sorter: false
        },
        {
          title: '报关单号',
          dataIndex: 'declare_num',
          sorter: false
        },
        {
          title: '主单号',
          dataIndex: 'order_num',
          sorter: false
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_info'
        },
        {
          title: '箱号',
          dataIndex: 'box_num'
        },
        {
          title: '总体积',
          dataIndex: 'volume'
        },
        {
          title: '总件数',
          dataIndex: 'total_num'
        },
        {
          title: '总天数',
          dataIndex: 'total_day'
        },
        {
          title: '合约号',
          dataIndex: 'deal_num'
        },
        {
          title: '应收备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 拖运业务表格展示列
      conShipmentColumns: [
        {
          title: '订单编号',
          dataIndex: 'serial_num'
        },
        {
          title: '报价编号',
          dataIndex: 'quota_num'
        },
        {
          title: '订单类型',
          dataIndex: 'order_kind',
          customRender: (text) => {
            for (let i = 0; i < this.orderKindOps.length; i++) {
              if (text === this.orderKindOps[i].value) {
                return this.orderKindOps[i].name
              }
            }
            return '无'
          }
        },
        {
          title: '拖运业务日期',
          dataIndex: 'business_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '拖运ep号',
          dataIndex: 'ep_no'
        },
        {
          title: '拖运编号',
          dataIndex: 'business_no'
        },
         {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 120
        },
        {
          title: '应收费用',
          dataIndex: 'charge_fees',
          width: 320,
          scopedSlots: { customRender: 'chargeFeeItem' }
        },
        {
          title: '应收合计',
          dataIndex: 'charge_total'
        },
        {
          title: '应付费用',
          dataIndex: 'pay_fees',
          width: 320,
          scopedSlots: { customRender: 'payFeeItem' }
        },
        {
          title: '应付合计',
          dataIndex: 'pay_total'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getItmsChargeFinancePage(Object.assign(parameter, this.queryParam)).then(res => {
          if (this.queryParam['order_kind'] === 5) {
            this.defaultColumns = this.conShipmentColumns
          } else {
            this.defaultColumns = this.otherColumns
          }
          this.showTotal = '箱型合计：' + res.total_row
          return res
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      selectedVisible: false,
      selectedSummary: '',
      balanceVisible: false,
      balanceConfirmLoading: false,
      indeterminate: false,
      checkAll: true,
      checkedBalanceList: [],
      feeOps: [],
      payTargetOps: [],
      balanceData: [],
      auditVisible: false,
      auditConfirmLoading: false,
      auditType: 1,
      auditData: [],
      checkedAuditList: [],
      payAuditList: [],
      auditIndeterminate: false,
      auditCheckAll: true,
      balanceDetailVisible: false,
      balanceColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '结算单编号',
          dataIndex: 'serialNum'
        },
        {
          title: '报价编号',
          dataIndex: 'quotaNum'
        },
        {
          title: '接单日期',
          dataIndex: 'receivingDate'
        },
        {
          title: '业务类型',
          dataIndex: 'orderType'
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '客户',
          dataIndex: 'customer'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      balanceDetailData: [],
      printItmsBillModel: false,
      printItmsBillModelLoading: false,
      printItmsBill: {
        id: 'printItmsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentData: {},
      balanceBillData: [],
      paymentDetailData: [],
      paymentDetailVisible: false,
      paymentColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '付款单编号',
          dataIndex: 'serialNum'
        },
        {
          title: '报价编号',
          dataIndex: 'quotaNum',
          scopedSlots: { customRender: 'quotaNumList' }
        },
        {
          title: '接单日期',
          dataIndex: 'receivingDate',
          scopedSlots: { customRender: 'receivingDateList' }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '付款对象',
          dataIndex: 'customer'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printData: [],
      orderType: '国贸部',
      paymentVisible: false,
      paymentConfirmLoading: false,
      checkedPayTarget: null,
      paymentPayTarget: [],
      payIndeterminate: false,
      payCheckAll: true,
      checkedPaymentList: [],
      paymentData: [],
      orderKindOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 },
        { name: '行政业务', value: 4 },
        { name: '拖运业务', value: 5 }
      ],
      // 审核、反审核展示应收总和、应付总和、利润总和
      auditChargeTotal: 0,
      auditPayTotal: 0,
      auditHFPayTotal: 0, // 拖运业务、恒发应付总和
      auditPLPayTotal: 0, // 拖运业务、鹏路应付总和
      auditSLPayTotal: 0, // 拖运业务、三利应付总和
      auditZJPayTotal: 0, // 拖运业务、召集应付总和
      auditProfitTotal: 0
    }
  },
  created() {
    getCommonOptions('fee', { department: 2, type: 2 }).then(v => {
      this.feeOps = v
    })
    getSupplier({ department: 2 }).then(res => {
      this.payTargetOps = res
    })
  },
  watch: {
    selectedRows: function() {
      if (this.selectedRows.length === 0) {
        this.selectedVisible = false
      } else {
        this.selectedSummary = `选择条数:${this.selectedRows.length}`
        this.selectedVisible = true
      }
    }
  },
  methods: {
    filterOption,
    conShipmentDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['business_start_date'] = startDate
        this.queryParam['business_end_date'] = endDate
      } else {
        this.queryParam['business_start_date'] = null
        this.queryParam['business_end_date'] = null
      }
    },
    // 创建付款单费用表格回调
    getPaymentFeeInfoTableDate(e) {
      let payMoney = 0
      this.paymentData = e
      e.forEach(item => {
        payMoney += item.submit_amount
      })
      this.payMoney = payMoney
    },
    rowAmountFunc(data, value) {
      let payMoney = 0
      this.paymentData.forEach(item => {
        payMoney += item.submit_amount
      })
      this.payMoney = payMoney
      return value
    },
    // 初始化付款合计
    payMoneyInit() {
      let payMoney = 0
      this.paymentData.forEach(item => {
        payMoney += item.money
        item.submit_amount = item.money
      })
      this.payMoney = payMoney
    },
    // 付款对象更改回调
    changePayTarget(e) {
      this.paymentData = this.payData.filter(fee => fee.pay_target_id === this.checkedPayTarget)
      this.payMoneyInit()
    },
    paymentSubmit() {
      this.paymentConfirmLoading = true
      const feeParamList = []
      this.paymentData.forEach(item => {
        feeParamList.push({
          'fee_id': item.id,
          'submit_amount': item.submit_amount
        })
      })
      createItmsPayment({
        fee_param_list: feeParamList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '创建付款单成功'
            })
          }
        })
        .finally(_ => {
          this.selectedRowKeys = []
          this.clearSelectItem()
          this.$refs.table.refresh(false)
          this.paymentVisible = false
          this.paymentConfirmLoading = false
        })
    },
    // 创建付款单
    createPayment() {
      const payData = []
      let payMoney = 0
      for (const item of this.selectedRows) {
        if (item.pay_fees) {
          for (const fee of item.pay_fees) {
            if (fee.fee_status === 2 && fee.is_payment === 1) {
              fee.quota_num = item.quota_num
              payData.push(fee)
            }
          }
        }
      }
      this.payData = payData
      const data = []
      for (const item of payData) {
        let exist = false
        for (const payTarget of data) {
          if (payTarget.id === item.pay_target_id) {
            exist = true
          }
        }
        if (!exist) {
          data.push({
            id: item.pay_target_id,
            name: item.pay_target_name
          })
        }
      }
      this.paymentPayTarget = data
      let paymentData = []
      if (data.length > 0) {
        this.checkedPayTarget = data[0].id
        paymentData = payData.filter(fee => fee.pay_target_id === data[0].id)
        paymentData.forEach(item => {
          payMoney += item.money
          item.submit_amount = item.money
        })
      }
      // this.checkedPaymentList = paymentData.map(item => item.id)
      this.paymentData = paymentData
      this.payMoney = payMoney.toFixed(2)
      if (paymentData.length > 0) {
        this.paymentVisible = true
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '没有已审核未加入付款单的费用'
        })
      }
    },
    // 查看付款单
    paymentDetail(record) {
      getPaymentDetailByOrderId(record.order_id).then(res => {
        this.paymentDetailData = res
        this.paymentDetailVisible = true
      })
    },
    // 费用颜色
    chargeFeeStatusColor(value) {
      if (value.fee_status === 1) {
        return '#faad14'
      } else {
        if (value.fee_status === 2 && value.is_balance === 1) {
          return '#1890ff'
        } else {
          return '#4cd964'
        }
      }
    },
    payFeeStatusColor(value) {
      if (value.fee_status === 1) {
        return '#faad14'
      } else {
        if (value.fee_status === 2 && value.is_payment === 1) {
          return '#1890ff'
        } else {
          return '#4cd964'
        }
      }
    },
    // 结算单
    feeChargeStatusName(value) {
      if (value.fee_status === 1) {
        return '待审核'
      } else {
        if (value.fee_status === 2 && value.is_balance === 1) {
          return '已审核'
        } else {
          return '添加到结算单'
        }
      }
    },
    // 付款单
    feePayStatusName(value) {
      if (value.fee_status === 1) {
        return '待审核'
      } else {
        if (value.fee_status === 2 && value.is_payment === 1) {
          return '已审核'
        } else {
          return '添加到付款单'
        }
      }
    },
    // 创建结算单
    createBalance() {
      const customerNameSet = new Set()
      for (const item of this.selectedRows) {
        customerNameSet.add(item.agency_name)
      }
      if (customerNameSet.size > 1) {
        this.$notification['warning']({
          message: '提示',
          description: '委托客户不同，不能生成在一张结算单中'
        })
        return
      }
      const data = this.selectedRows.map(item => {
        return {
          id: item.order_id,
          quota_num: item.quota_num,
          charge: item.charge_fees
            ? item.charge_fees.filter(item => item.fee_status === 2 && item.is_balance === 1)
            : [],
          pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status >= 2 && item.is_balance === 1) : []
        }
      })
      this.balanceData = data
      let chargeMoney = 0
      let payMoney = 0
      let profit = 0
      const charge = data
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            chargeMoney += e.money
            profit += e.money
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      const pay = data
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            payMoney += e.money
            profit -= e.money
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.checkedBalanceList = chargeIdList.concat(payIdList)
      this.chargeMoney = chargeMoney.toFixed(2)
      this.payMoney = payMoney.toFixed(2)
      this.profit = profit.toFixed(2)
      if (this.checkedBalanceList.length === 0) {
        this.$notification['warn']({
          message: '提示',
          description: '没有已审核未加入结算单的费用'
        })
      } else {
        this.balanceVisible = true
      }
    },
    // 结算单提交
    balanceSubmit() {
      this.balanceConfirmLoading = true
      createItmsBalance({
        fee_ids: this.checkedBalanceList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '创建结算单成功'
            })
            this.balanceVisible = false
            this.$refs.table.refresh(false)
          }
        })
        .finally(_ => {
          this.balanceConfirmLoading = false
          this.clearSelectItem()
        })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      // this.selectedRows = selectedRows
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 2,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.queryParam['auth_agency'] = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam['auth_agency'] = null
        this.customerOps = []
        this.fetching = false
      }
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        let flag = true
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_id === record.order_id) {
            flag = false
          }
        }
        if (flag) {
          this.selectedRows.push(record)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_id === record.order_id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    removeSelectItem(orderId) {
      for (let index = 0; index < this.selectedRowKeys.length; index++) {
        if (this.selectedRowKeys[index] === orderId) {
          this.selectedRowKeys.splice(index, 1)
        }
      }
      for (let index = 0; index < this.selectedRows.length; index++) {
        if (this.selectedRows[index].order_id === orderId) {
          this.selectedRows.splice(index, 1)
        }
      }
    },
    clearSelectItem() {
      this.$refs.table.clearSelected()
      this.selectedRows = []
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.order_id === item.order_id) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (const item of changeRows) {
          for (let index = 0; index < this.selectedRows.length; index++) {
            if (item.order_id === this.selectedRows[index].order_id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
      // const moments = []
      // if (date[0]) {
      //   moments.push(date[0].startOf('day').valueOf())
      //   moments.push(date[1].endOf('day').valueOf())
      // }
      // this.queryParam['@created_at'] = moments
    },
    balanceDetail(record) {
      getBalanceDetailByOrderId(record.order_id).then(res => {
        this.balanceDetailData = res
        this.balanceDetailVisible = true
      })
    },
    // 结算单详细
    previewChargeDetail(record) {
      getItmsBalanceFunc('list_balance', { id: record.balanceId })
        .then(res => {
          // 统计数据
          let charge = 0
          let pay = 0
          let profit = 0
          res.balance_item_list.forEach(v => {
            v.charge_fees &&
              v.charge_fees.forEach(c => {
                if (c.currency_name === 'CNY') {
                  charge += c.amount
                } else {
                  charge += c.exchange_amount
                }
              })
            v.pay_fees &&
              v.pay_fees.forEach(c => {
                if (c.currency_name === 'CNY') {
                  pay += c.amount
                } else {
                  pay += c.exchange_amount
                }
              })
            profit += v.profit
          })
          this.balanceStatic = {
            charge: '应收总计:' + charge.toFixed(2),
            pay: '应付总计:' + pay.toFixed(2),
            profit: '利润总计:' + profit.toFixed(2)
          }
          this.balanceBillData = res.balance_item_list
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceStatic = this.balanceStatic
          this.currentData.currency_name = res.currency
          this.currentData.customer_name = record.customer
          this.currentData.record_no = record.serialNum
          this.currentData.creator_name = record.createName
          if (record.remark) {
            this.currentData.apply_remark = record.remark
          }
        })
        .finally(() => {
          this.printItmsBillModel = true
        })
    },
    // 付款单详细
    previewPayDetail(record) {
      getItmsPayBillFunc('payFeeBill', { id: record.paymentId }).then(v => {
        this.printData = v
        this.printPayStatementsBillModel = true
      })
    },
    auditOpen(type) {
      if (type === 'basic_audit') {
        this.auditType = 1
        const data = this.selectedRows.map(item => {
          return {
            id: item.order_id,
            quota_num: item.quota_num,
            charge: item.charge_fees ? item.charge_fees.filter(item => item.fee_status === 1) : [],
            pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status === 1) : []
          }
        })
        this.auditData = data
        // 计算应收应付合计
        let chargeTotal = 0
        let payTotal = 0
        let HFPayTotal = 0
        let PLPayTotal = 0
        let SLPayTotal = 0
        let ZJPayTotal = 0
        const idList = []
        this.selectedRows.forEach(item => {
          if (item.charge_fees) {
            item.charge_fees.forEach(chargeFeeItem => {
              if (chargeFeeItem.fee_status === 1) {
                idList.push(chargeFeeItem.id)
              }
              chargeTotal += chargeFeeItem.money
            })
          }
          if (item.pay_fees) {
            item.pay_fees.forEach(payFeeItem => {
              if (payFeeItem.fee_status === 1) {
                idList.push(payFeeItem.id)
              }
              if (payFeeItem.pay_target_id === 4505) {
                HFPayTotal += payFeeItem.money
              }
              if (payFeeItem.pay_target_id === 5742) {
                PLPayTotal += payFeeItem.money
              }
              if (payFeeItem.pay_target_id === 4571) {
                SLPayTotal += payFeeItem.money
              }
              if (payFeeItem.pay_target_id === 6739) {
                ZJPayTotal += payFeeItem.money
              }
              payTotal += payFeeItem.money
            })
          }
        })
        this.auditChargeTotal = chargeTotal
        this.auditPayTotal = payTotal
        this.auditHFPayTotal = HFPayTotal
        this.auditPLPayTotal = PLPayTotal
        this.auditSLPayTotal = SLPayTotal
        this.auditZJPayTotal = ZJPayTotal
        this.auditProfitTotal = chargeTotal - payTotal
        this.checkedAuditList = idList
        if (this.checkedAuditList.length > 0) {
          this.auditVisible = true
        } else {
          this.$notification['warn']({
            message: '提示',
            description: '没有可以审核的费用'
          })
        }
      } else {
        this.auditType = 2
        const data = this.selectedRows.map(item => {
          return {
            id: item.order_id,
            quota_num: item.quota_num,
            charge: item.charge_fees ? item.charge_fees.filter(item => item.fee_status === 2 && item.is_balance === 1) : [],
            pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status === 2 && item.is_payment === 1) : []
          }
        })
        this.auditData = data
        let chargeTotal = 0
        let payTotal = 0
        let HFPayTotal = 0
        let PLPayTotal = 0
        let SLPayTotal = 0
        let ZJPayTotal = 0
        const idList = []
        this.selectedRows.forEach(item => {
          if (item.charge_fees) {
            item.charge_fees.forEach(chargeFeeItem => {
              if (chargeFeeItem.fee_status === 2 && chargeFeeItem.is_balance === 1) {
                idList.push(chargeFeeItem.id)
              }
              chargeTotal += chargeFeeItem.money
            })
          }
          if (item.pay_fees) {
            item.pay_fees.forEach(payFeeItem => {
              if (payFeeItem.fee_status === 2 && payFeeItem.is_payment === 1) {
                idList.push(payFeeItem.id)
              }
              if (payFeeItem.pay_target_id === 4505) {
                HFPayTotal += payFeeItem.money
              }
              if (payFeeItem.pay_target_id === 5742) {
                PLPayTotal += payFeeItem.money
              }
              if (payFeeItem.pay_target_id === 4571) {
                SLPayTotal += payFeeItem.money
              }
              if (payFeeItem.pay_target_id === 6739) {
                ZJPayTotal += payFeeItem.money
              }
              payTotal += payFeeItem.money
            })
          }
        })
        this.auditChargeTotal = chargeTotal
        this.auditPayTotal = payTotal
        this.auditHFPayTotal = HFPayTotal
        this.auditPLPayTotal = PLPayTotal
        this.auditSLPayTotal = SLPayTotal
        this.auditZJPayTotal = ZJPayTotal
        this.auditProfitTotal = chargeTotal - payTotal
        this.checkedAuditList = idList
        if (this.checkedAuditList.length > 0) {
          this.auditVisible = true
        } else {
          this.$notification['warn']({
            message: '提示',
            description: '没有可以反审核的费用'
          })
        }
      }
    },
    submitAudit() {
      this.auditConfirmLoading = true
      const applyName = this.auditType === 1 ? 'basic_audit' : 'undo_audit'
      ItmsFinanceFunc(applyName, {
        ids: this.checkedAuditList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            const feeStatus = applyName === 'basic_audit' ? 2 : 1
            this.selectedRows.forEach(item => {
              if (item.charge_fees) {
                item.charge_fees.forEach(fee => {
                  if (this.checkedAuditList.indexOf(fee.id) >= 0) {
                    fee.fee_status = feeStatus
                  }
                })
              }
              if (item.pay_fees) {
                item.pay_fees.forEach(fee => {
                  if (this.checkedAuditList.indexOf(fee.id) >= 0) {
                    fee.fee_status = feeStatus
                  }
                })
              }
            })
          }
        })
        .finally(res => {
          this.auditVisible = false
          this.auditConfirmLoading = false
        })
    },
    handleExport(type) {
      const params = this.queryParam
      exportItmsFinance(type, params).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    onChangeBalance(checkedValues) {
      let chargeMoney = 0
      let payMoney = 0
      let profit = 0
      const charge = this.balanceData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (checkedValues.indexOf(e.id) >= 0) {
              chargeMoney += e.money
              profit += e.money
            }
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      const pay = this.balanceData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (checkedValues.indexOf(e.id) >= 0) {
              payMoney += e.money
              profit -= e.money
            }
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.chargeMoney = chargeMoney.toFixed(2)
      this.payMoney = payMoney.toFixed(2)
      this.profit = profit.toFixed(2)
      this.indeterminate =
        !!this.checkedBalanceList.length && this.checkedBalanceList.length < chargeIdList.length + payIdList.length
      this.checkAll = this.checkedBalanceList.length === chargeIdList.length + payIdList.length
    },
    onCheckAllChangeBalance(e) {
      this.checkAll = e.target.checked
      let chargeMoney = 0
      let payMoney = 0
      let profit = 0
      this.indeterminate = false
      this.checkedBalanceList = []
      const charge = this.balanceData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (this.checkAll) {
              chargeMoney += e.money
              profit += e.money
            }
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      const pay = this.balanceData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (this.checkAll) {
              payMoney += e.money
              profit -= e.money
            }
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.profit = profit.toFixed(2)
      this.chargeMoney = chargeMoney.toFixed(2)
      this.payMoney = payMoney.toFixed(2)
      if (e.target.checked) {
        this.checkedBalanceList = this.checkedBalanceList.concat(chargeIdList, payIdList)
      }
    },
    onChangeAudit(checkedValues) {
      // 应收
      const charge = this.auditData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      // 应付
      const pay = this.auditData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.auditIndeterminate =
        !!this.checkedAuditList.length && this.checkedAuditList.length < chargeIdList.length + payIdList.length
      this.auditCheckAll = this.checkedAuditList.length === chargeIdList.length + payIdList.length
    },
    onCheckAllChangeAudit(e) {
      this.auditCheckAll = e.target.checked
      this.auditIndeterminate = false
      this.checkedAuditList = []
      const charge = this.auditData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      if (e.target.checked) {
        this.checkedAuditList = this.checkedAuditList.concat(chargeIdList)
      }
    },
    getContainer() {
      return document.getElementById('selectArea')
    }
  }
}
</script>

<style>
.row-footer .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: none;
}
.selectContextOpen {
  margin: 0;
}
.selectContextClose {
  margin: 0;
  height: 320px;
  overflow: auto;
}
.fee-area {
  display: flex;
  flex-direction: row;
}
.fee-list {
  flex: 1;
}
.select-summary {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
.select-list-item {
  margin-top: 10px;
  padding: 5px 10px;
  border: 1px solid rgb(231, 231, 231);
}
</style>
